/* External dependencies */
import { Col, Row, Text } from 'atomize';
import { FormattedMessage, navigate } from 'gatsby-plugin-react-intl';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/* Local dependencies */
import formatMessage from '../../intl/formatMessage';
import { RootState } from '../../redux/store';
import PrimaryButton from '../buttons/PrimaryButton';
import FormField from '../form/FormField';
import { rowStyle } from '../form/FormStyles';
import ErrorPopup from '../popup/ErrorPopup';
import PrimaryPopup from '../popup/PrimaryPopup';
import SuccessPopup from '../popup/SuccessPopup';
import {
  createWarehouseRequest,
  resetCreateWarehouse,
  resetError,
  updateWarehouseName,
} from './redux/actions';

export default function CreateWarehouseForm() {
  const dispatch = useDispatch();
  const { error, isSuccess, name, nameError, loading } = useSelector(
    (state: RootState) => state.createWarehouse,
  );

  const [isConfirmationPopupVisible, setConfirmationState] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(resetCreateWarehouse());
    };
  }, []);

  function onInputChange(e) {
    const { value } = e.target;

    dispatch(updateWarehouseName(value));
  }

  const errorMessage = error && formatMessage(error.code);

  const isInvalid = nameError || !name;

  function openConfirmationPopup(e) {
    e.preventDefault();
    setConfirmationState(true);
  }

  function closeConfirmationPopup() {
    setConfirmationState(false);
  }

  function onSubmit() {
    dispatch(createWarehouseRequest(name));

    setConfirmationState(false);
  }

  return (
    <form onSubmit={openConfirmationPopup}>
      <Row {...rowStyle} align="baseline">
        <FormField
          colSize="7"
          error={nameError}
          label={formatMessage('warehouseName')}
          inputValue={name}
          inputType="text"
          inputName="name"
          placeholder={formatMessage('warehouseNamePlaceholder')}
          onChange={onInputChange}
          required={true}
          tabIndex="1"
        />
      </Row>
      <PrimaryButton
        icon="Checked"
        size="lg"
        disabled={isInvalid || loading}
        loading={loading}
      >
        <FormattedMessage id="addWarehouse" />
      </PrimaryButton>
      {isConfirmationPopupVisible && (
        <PrimaryPopup
          title={formatMessage('addWarehouseQuestion')}
          cancelBtnText={formatMessage('cancel')}
          onClose={closeConfirmationPopup}
          btnText={formatMessage('add')}
          onClick={onSubmit}
        >
          <Row>
            <Col>
              <Text>{name}</Text>
            </Col>
          </Row>
        </PrimaryPopup>
      )}
      {isSuccess && !isInvalid && (
        <SuccessPopup
          onSubmit={() => {
            navigate('/warehouses');
          }}
          submitText={formatMessage('close')}
          title={formatMessage('warehouseAdded')}
        >
          {name}
        </SuccessPopup>
      )}
      {error && (
        <ErrorPopup
          onSubmit={() => dispatch(resetError())}
          submitText={formatMessage('close')}
          title={formatMessage('errorOccurred')}
        >
          {errorMessage}
        </ErrorPopup>
      )}
    </form>
  );
}
