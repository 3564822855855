/* External dependencies */
import { Col, Container, Row, Text } from 'atomize';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';

/* Local dependencies */
import Layout from '../../../components/layout/';
import BackNav from '../../../components/nav/BackNav';
import formatMessage from '../../../intl/formatMessage';
import CreateWarehouseForm from '../../../components/create-warehouse';

export default function CreateWarehousePage() {
  const title = formatMessage('addWarehouse');
  const {
    site: {
      siteMetadata: { titleTemplate },
    },
  } = useStaticQuery(query);

  const onClickBackNav = () => {
    navigate('/warehouses');
  };

  return (
    <Layout>
      <Helmet>
        <title>{titleTemplate.replace('%s', title)}</title>
      </Helmet>
      <Container>
        <Row>
          <Col size={{ xs: '12', lg: '6' }} maxW="600px" w="100%">
            <BackNav
              label={formatMessage('newWarehouse')}
              onClick={onClickBackNav}
            />
            <CreateWarehouseForm />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

const query = graphql`
  query {
    site {
      siteMetadata {
        titleTemplate
      }
    }
  }
`;
